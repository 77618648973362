<template>
    <h2>
        Seite nicht gefunden!
    </h2>
    <p>Möglicherweise möchten Sie sich im<router-link to="/login" class="btn text-primary" >Shop</router-link>umsehen?</p>
</template>




<style scoped>


</style>