<template>
    <main>
      <h4 class="subtitle is-2">Abos</h4>
      <div v-if="isLoading">
        <base-spinner></base-spinner>
      </div>
      <div class="row" v-else-if="hasAbos">
        <abo-list-item
          v-for="item in abosList"
          :key="item.id"
          :id="item.id"
          :Abotyp="item.Abotyp"
          :abo_name="item.abo_name"
          :abotyp_id="item.abotyp_id"
          :abo_dauer="item.abo_dauer"
          :erstellt="item.erstellt"
          :bezahlt="item.bezahlt"
          :bezahlt_seit="item.bezahlt_seit"
          :miniserver_ip="item.miniserver_ip"
          :rufnummer="item.rufnummer"
          :Aktivierungskey="item.Aktivierungskey"
          :Rufnummernbestaetigung="item.Rufnummernbestaetigung"
          :Bezahltyp_id="item.Bezahltyp_id"
          :is_expired="item.is_expired"
        ></abo-list-item>
      </div>
      <h3 v-else>Keine Daten gefunden.</h3>
    </main>
</template>

<script>
import AboListItem from '../../components/abo/AboListItem.vue';
export default {
  computed: {
    hasAbos() {
      console.log(this.$store.getters.HasAdminAbos);
      return !this.isLoading && this.$store.getters.HasAdminAbos;
    },
    abosList() {
      console.log(this.$store.getters.AdminAbos);
      return !this.isLoading && this.$store.getters.AdminAbos;
    },
  },
  components: {
    AboListItem,
  },
  data() {
    return {
      isLoading: false,
      error: null,
    };
  },
  created() {
    this.loadAbos();
    this.closeMenu();
  },
  methods: {
    closeMenu() {
      var toggler = document.getElementById('navbarCollapse');
      try {
        toggler.classList.remove("hide");
      } catch (error) {
        toggler.classList.remove("show");
      } 

    },
    async loadAbos(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('getadminabos', {
          forceRefresh: refresh,
          token: this.$store.state.auth.sessiontoken,
          userid: this.$store.state.auth.userId,
        });

        // console.log(this.$store.state.auth.sessiontoken);
        // console.log(this.$store.state.auth.userId);
      } catch (error) {
        this.error = error.message || 'Something went wrong!';
      }
      this.isLoading = false;
    },
    handleError() {
      this.error = null;
    },
  },
};
</script>