<template>
    <main>
      <div class="row">
        <div class="col-11">
          <h4 class="subtitle is-2">History</h4>
        </div>
        <div v-if="tablemode == 2" class="col-1">
          <button class="btn" @click="switchToGridButton" ><i class="fa-solid fa-grip"></i></button>
        </div>
        <div v-if="tablemode == 1" class="col-1">
          <button class="btn"  @click="switchToTableButton" ><i class="fa-solid fa-table-list"></i></button>
        </div>
      </div>
      <div v-if="isLoading">
        <base-spinner></base-spinner>
      </div>
      <div class="row" v-else-if="hasPhonehistory&&tablemode==1">
          <phone-history-list-item
            v-for="sHistentry in histList"
            :key="sHistentry.uniqueid"
            :id="sHistentry.uniqueid"
            :calldate="sHistentry.calldate"
            :src="sHistentry.src"
            :dst="sHistentry.dst"
            :dcontext="sHistentry.dcontext"
            :destchannel="sHistentry.destchannel"
            :lastapp="sHistentry.lastapp"
            :lastdata="sHistentry.lastdata"
            :duration="sHistentry.duration"
            :billsec="sHistentry.billsec"
            :disposition="sHistentry.disposition"
            :uniqueid="sHistentry.uniqueid"
          ></phone-history-list-item>
      </div>
      <table  class="table  table-striped" v-else-if="hasPhonehistory&&tablemode==2">
        <thead>
          <tr>
            <th scope="col">Type</th>
            <th scope="col">Von</th>
            <th scope="col">Zu</th>
            <th scope="col">Dauer</th>
            <th scope="col">Zeitpunkt</th>
          </tr>
        </thead>
        <tbody>
          <phone-history-list-table-item
            v-for="sHistentry in histList"
            :key="sHistentry.uniqueid"
            :id="sHistentry.uniqueid"
            :calldate="sHistentry.calldate"
            :src="sHistentry.src"
            :dst="sHistentry.dst"
            :dcontext="sHistentry.dcontext"
            :destchannel="sHistentry.destchannel"
            :lastapp="sHistentry.lastapp"
            :lastdata="sHistentry.lastdata"
            :duration="sHistentry.duration"
            :billsec="sHistentry.billsec"
            :disposition="sHistentry.disposition"
            :uniqueid="sHistentry.uniqueid"
          ></phone-history-list-table-item>
        </tbody>
      </table >
      <h3 v-else>Keine Daten gefunden.</h3>
    </main>
</template>

<script>
import PhoneHistoryListItem from '../../components/admin/PhoneHistoryListItem.vue';
import PhoneHistoryListTableItem from '../../components/admin/PhoneHistoryListTableItem.vue';
export default {
  computed: {
    hasPhonehistory() {
      // console.log(this.$store.getters['profile/HasProfiles']);
      // return !this.isLoading && this.$store.getters['profile/HasProfiles'];
      console.log(this.$store.getters.HasPhonehistory);
      return !this.isLoading && this.$store.getters.HasPhonehistory;
    },
    histList() {
      console.log("Phonehistory: ");
      // console.log(this.$store.getters['profile/Profiles']);
      // return !this.isLoading && this.$store.getters['profile/Profiles'];
      console.log(this.$store.getters.Phonehistory);
      return !this.isLoading && this.$store.getters.Phonehistory;
    },
  },
  components: {
    PhoneHistoryListItem,
    PhoneHistoryListTableItem,
  },
  data() {
    return {
      isLoading: false,
      tablemode: 2, // 1 = grid, 2 = table
      error: null,
    };
  },
  created() {
    this.loadHistlist();
    this.closeMenu();
  },
  methods: {
    closeMenu() {
      var toggler = document.getElementById('navbarCollapse');
      try {
        toggler.classList.remove("hide");
      } catch (error) {
        toggler.classList.remove("show");
      } 

    },
    async loadHistlist(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('getphonehistory', {
          forceRefresh: refresh,
          token: this.$store.state.auth.sessiontoken,
          userid: this.$store.state.auth.userId,
        });

      } catch (error) {
        this.error = error.message || 'Something went wrong!';
      }
      this.isLoading = false;
    },
    handleError() {
      this.error = null;
    },
    switchToGridButton() {
            this.tablemode = 1;
            console.log('tablemode: ' + this.tablemode);
    },
    switchToTableButton() {
      this.tablemode = 2;
        console.log('tablemode: ' + this.tablemode);
    },
  },
};
</script>

<style>

.phone-list-item-enter-from {
  opacity: 0;
  translate: translateX(-90px);
}
.phone-list-item-enter-active {
  transition: all 4s ease-in
}
.phone-list-item-enter-to {
  
  opacity: 1;
  translate: translateX(0);
}
</style>