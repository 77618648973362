<template>
    <div id="paypal-button-container-P-0WJ47538WV475144UM4V7FEQ"></div>

</template>


<!-- eslint-disable -->
<script >
export default {  
  components: {
    // loadScript,
  },
  emits: ['displayApproval'],
  data() {
    return {
      isLoading: false,
      error: null,
      
    };
  },
  mounted() {
    // Script was unloaded successfully
    paypal_subscriptions.Buttons({
      style: {
          shape: 'rect',
          color: 'gold',
          layout: 'vertical',
          label: 'subscribe'
      },
      createSubscription: function(data, actions) {
        return actions.subscription.create({
          /* Creates the subscription */
          plan_id: 'P-0WJ47538WV475144UM4V7FEQ'
        });
      },
      onApprove: function(data, actions) {
        alert(data.subscriptionID); // You can add optional success message for the subscriber here
        const url = 'https://kundenportal.sidanet.at/vueapi/paypalsuccess.php?subscriptionID='+data.subscriptionID;
        console.log(url);
        // Make the request 
        fetch(url, {
                    cache: 'no-store',
                    method: 'GET',
                    headers: {
                      'Access-Control-Allow-Origin': "*",
                      "Access-Control-Allow-Methods": "GET,HEAD,POST,OPTIONS",
                      "Access-Control-Max-Age": "86400",
                    },
                  }) 
            .then(response => response.json())
            .then(data => console.log("Data:" + data)) 
            .catch(error => console.error('Error:', error)); 
        // go to abo page
        // this.$emit('displayApproval',"Subscription successful payed!");
      }
    }).render('#paypal-button-container-P-0WJ47538WV475144UM4V7FEQ'); // Renders the PayPal button -->
  },
  methods: {
  }
};

</script>
