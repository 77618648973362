<template>
    <main>
      <h4 class="subtitle is-2">Meine Benutzerdaten</h4>
      <div v-if="isLoading">
        <base-spinner></base-spinner>
      </div>
      <div class="grid has-5-cols" v-else-if="hasProfiles">
        <profile-abo-list-item
          v-for="sProfile in profilesList"
          :key="sProfile.id"
          :id="sProfile.id"
          :benutzername="sProfile.benutzername"
          :vorname="sProfile.vorname"
          :nachname="sProfile.nachname"
          :strasse="sProfile.strasse"
          :hausnummer="sProfile.hausnummer"
          :plz="sProfile.plz"
          :ort="sProfile.ort"
          :land="sProfile.land"
          :email="sProfile.email"
          :aktiv="sProfile.aktiv"
          :erstellt="sProfile.erstellt"
        ></profile-abo-list-item>
      </div>
      <h3 v-else>Keine Daten gefunden.</h3>
    </main>
</template>

<script>
import ProfileAboListItem from '../../components/profile/ProfileAboListItem.vue';
export default {
  computed: {
    hasProfiles() {
      // console.log(this.$store.getters['profile/HasProfiles']);
      // return !this.isLoading && this.$store.getters['profile/HasProfiles'];
      console.log(this.$store.getters.HasProfiles);
      return !this.isLoading && this.$store.getters.HasProfiles;
    },
    profilesList() {
      console.log("Profiles: ");
      // console.log(this.$store.getters['profile/Profiles']);
      // return !this.isLoading && this.$store.getters['profile/Profiles'];
      console.log(this.$store.getters.Profiles);
      return !this.isLoading && this.$store.getters.Profiles;
    },
  },
  components: {
    ProfileAboListItem,
  },
  data() {
    return {
      isLoading: false,
      error: null,
    };
  },
  created() {
    this.loadProfiles();
    this.closeMenu();
  },
  methods: {
    closeMenu() {
      var toggler = document.getElementById('navbarCollapse');
      try {
        toggler.classList.remove("hide");
      } catch (error) {
        toggler.classList.remove("show");
      } 

    },
    async loadProfiles(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('getprofile', {
          forceRefresh: refresh,
          token: this.$store.state.auth.sessiontoken,
          userid: this.$store.state.auth.userId,
        });
      } catch (error) {
        this.error = error.message || 'Something went wrong!';
      }
      this.isLoading = false;
    },
    handleError() {
      this.error = null;
    },
  },
};
</script>