<template>
    <main class="flex-shrink-0">
      <section class="py-5 text-center container" style="background-image: url('/public/images/DSC00376-HDR-2 zugeschnitten.jpg'); background-position: 0% 50px; margin-bottom: 90vmin;">
        <!-- <div class="row py-lg-5">
          <div class="col-lg-6 col-md-8 mx-auto sidatitle">
            <br>
            <h2 id="sidafont1" class="fw-light">Willkommen</h2>
            <br><br>
            <p id="sidafont2" class="fw-light ">bei</p>
            <br><br><br><br>
            <p id="sidafont3" class="lead text-body-secondary shadows" style="font-size: 6ch;"><strong class=" text-primary">SI</strong>MPLE - <strong class=" text-primary">DA</strong>TA - <strong class=" text-primary">NET</strong>WORK</p>
          </div>
        </div> -->
        <!-- <h2 id="demo"> {{ innerHtmlText }}</h2> -->

        <div class="animated-title">
          <div class="text-top">
            <div>
              <span>Willkommen</span>
              <span>bei</span>
            </div>
          </div>
          <div class="text-bottom">
            <div class="position-relative text-center">
            </div>
            
              <span id="titlefirst"  class=" display-3" :class="sidanetlongclass" :hidden="sidanetlonghidden"> {{ innerHtmlText }}</span>
              <span id="titlesecond"  class="text-primary display-1" :class="sidanetclass" :hidden="sidanethidden"> {{ txtsmall }}</span>
              
            
          </div>
        </div>
      </section>
      <!-- <hr > -->
      <!-- <button class="btn" @click="trythisButton">Expand/Contract</button> -->
      <div class="container my-5 ">
        <div class="p-5 text-center rounded-3">
          <svg class="bi mt-4 mb-3" style="color: var(--bs-indigo);" width="100" height="100"><use xlink:href="#bootstrap"></use></svg>
          <h1 class="text-body-emphasis">Digitalisierung</h1>
          <p class="col-lg-8 mx-auto fs-5 text-muted">
            Ist nicht nur ein Modewort... 
          </p><br>
          <p class="col-lg-8 mx-auto fs-5 text-muted">
            Sondern die Chance einen Schritt voraus zu sein und den Leben leichter zu machen.
          </p><br>
          <br>
          <div class="row">
            <div class="col">
              <button class="btn btn-outline-secondary btn-lg px-4 rounded-pill" type="button">
                <i class="fa-solid fa-phone"></i> Anrufen
              </button>
              <button class="btn btn-lg px-4 rounded-pill" type="button">
                <i class="fa-solid fa-arrow-right fa-2xl"></i>
              </button>
            </div>
            <div class="col">
              <button class="d-inline-flex align-items-center btn btn-primary btn-lg px-4 rounded-pill" type="button">
                <i class="fa-solid fa-magnifying-glass"></i> Lösung definieren
              </button>
              <button class="btn btn-lg px-4 rounded-pill" type="button">
                <i class="fa-solid fa-arrow-right fa-2xl"></i>
              </button>
            </div>
            <div class="col">
              <button class="btn btn-outline-secondary btn-lg px-4 rounded-pill" type="button">
                <i class="fa-regular fa-handshake"></i> Realisiern
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <hr>
      <!-- News Section -->
      <div class="album py-5 bg-body-tertiary">
        <div class="container">

          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div class="col">
              <div class="card shadow-sm">
                <svg class="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"></rect><text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text></svg>
                <div class="card-body">
                  <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <button type="button" class="btn btn-sm btn-outline-secondary">View</button>
                      <button type="button" class="btn btn-sm btn-outline-secondary">Edit</button>
                    </div>
                    <small class="text-body-secondary">9 mins</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card shadow-sm">
                <svg class="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"></rect><text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text></svg>
                <div class="card-body">
                  <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <button type="button" class="btn btn-sm btn-outline-secondary">View</button>
                      <button type="button" class="btn btn-sm btn-outline-secondary">Edit</button>
                    </div>
                    <small class="text-body-secondary">9 mins</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card shadow-sm">
                <svg class="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"></rect><text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text></svg>
                <div class="card-body">
                  <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <button type="button" class="btn btn-sm btn-outline-secondary">View</button>
                      <button type="button" class="btn btn-sm btn-outline-secondary">Edit</button>
                    </div>
                    <small class="text-body-secondary">9 mins</small>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card shadow-sm">
                <svg class="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"></rect><text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text></svg>
                <div class="card-body">
                  <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <button type="button" class="btn btn-sm btn-outline-secondary">View</button>
                      <button type="button" class="btn btn-sm btn-outline-secondary">Edit</button>
                    </div>
                    <small class="text-body-secondary">9 mins</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card shadow-sm">
                <svg class="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"></rect><text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text></svg>
                <div class="card-body">
                  <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <button type="button" class="btn btn-sm btn-outline-secondary">View</button>
                      <button type="button" class="btn btn-sm btn-outline-secondary">Edit</button>
                    </div>
                    <small class="text-body-secondary">9 mins</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card shadow-sm">
                <svg class="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"></rect><text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text></svg>
                <div class="card-body">
                  <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <button type="button" class="btn btn-sm btn-outline-secondary">View</button>
                      <button type="button" class="btn btn-sm btn-outline-secondary">Edit</button>
                    </div>
                    <small class="text-body-secondary">9 mins</small>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card shadow-sm">
                <svg class="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"></rect><text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text></svg>
                <div class="card-body">
                  <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <button type="button" class="btn btn-sm btn-outline-secondary">View</button>
                      <button type="button" class="btn btn-sm btn-outline-secondary">Edit</button>
                    </div>
                    <small class="text-body-secondary">9 mins</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card shadow-sm">
                <svg class="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"></rect><text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text></svg>
                <div class="card-body">
                  <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <button type="button" class="btn btn-sm btn-outline-secondary">View</button>
                      <button type="button" class="btn btn-sm btn-outline-secondary">Edit</button>
                    </div>
                    <small class="text-body-secondary">9 mins</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card shadow-sm">
                <svg class="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"></rect><text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text></svg>
                <div class="card-body">
                  <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <button type="button" class="btn btn-sm btn-outline-secondary">View</button>
                      <button type="button" class="btn btn-sm btn-outline-secondary">Edit</button>
                    </div>
                    <small class="text-body-secondary">9 mins</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="my-5">
        <div class="p-5 text-center bg-body-tertiary">
          <div class="container py-5">
            <h1 class="text-body-emphasis">Bist du schon fit für die Zukunft?</h1>
            <p class="col-lg-8 mx-auto lead">
              Die Welt um uns herum ist im ständigen Wandel. Besonders die technischen Bereiche ändern und aktualisieren sich in immer kürzer werdenden Abständen. Vielen Menschen fällt es oft schwer mit den teschnischen und digitalen Neuerungen mitzuhalten. Mit meiner langjährigen Ehrfahrung helfe ich gerne jeden dabei, in der Welt der Digitalisierung up-to-date zu sein.
            </p>
          </div>
        </div>
      </div>


      <div class="container col-xxl-8 px-4 py-5">
        <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div class="col-10 col-sm-8 col-lg-4 ">
            <img src="/images/DSC00376-HDR-2 zugeschnitten.jpg" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="600" height="500" loading="lazy"  style="border-radius: 25px;">
          </div>
          <div class="col-lg-8">
            <h1 class="display-6 fw-bold text-body-emphasis lh-1 mb-3">Nichts ist für mich besser als dir wertvolle Zeit zurückzugeben</h1>
            <p class="lead">In den paar Jahren, die ich mittlerweile schon im IT-Bereich bin, konnte ich schon Freunde, Familien, Vereine, Selbstständige und Betriebe helfen.</p>
            <!-- <div class="d-grid gap-2 d-md-flex justify-content-md-start">
              <button type="button" class="btn btn-primary btn-lg px-4 me-md-2">Primary</button>
              <button type="button" class="btn btn-outline-secondary btn-lg px-4">Default</button>
            </div> -->
          </div>
        </div>
      </div>


      <hr>
      <div class="site-section">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 text-center aos-init aos-animate" data-aos="fade-up">
            <h4 class="heading font-weight-bold mb-3">Sie sind bereits zufriedene Kunden und machen den Schritt in die Zukunft</h4>
          </div>
        </div>
        
        <div class="row">
          <div class="col-lg-3">
            <img src="images/SPIEGEL_logo.jpg" alt="Image" style="max-height: 100px; width: auto;" class="img-fluid">
            <!-- <h6 class="fw-normal">Spiegel Waizenkirchen</h6> -->
            <!-- <p>Some representative placeholder content for the three columns of text below the carousel. This is the first column.</p> -->
            <!-- <p><a class="btn btn-secondary" href="#">View details »</a></p> -->
          </div><!-- /.col-lg-4 -->
          <div class="col-lg-3">
            <img src="images/elkiz-logo.png" alt="Image" style="max-height: 100px; width: auto;" class="img-fluid">
            <!-- <h6 class="fw-normal">Eltern-Kind-Zentrum Peuerbach</h6> -->
            <!-- <p>Another exciting bit of representative placeholder content. This time, we've moved on to the second column.</p> -->
            <!-- <p><a class="btn btn-secondary" href="#">View details »</a></p> -->
          </div><!-- /.col-lg-4 -->
          <div class="col-lg-3">
            <img src="images/WeberschLaeger_Logo_Farbe_Klein_CMYK.png" alt="Image" style="max-height: 100px; width: auto;" class="img-fluid">
            <!-- <h6 class="fw-normal">Weberschläger Ges.m.b.H</h6> -->
            <!-- <p>And lastly this, the third column of representative placeholder content.</p> -->
            <!-- <p><a class="btn btn-secondary" href="#">View details »</a></p> -->
          </div><!-- /.col-lg-4 -->
          <div class="col-lg-3">
            <img src="images/logo-von-ofen-und-holz.png" alt="Image" style="max-height: 100px; width: auto;" class="img-fluid">
            <!-- <h6 class="fw-normal">Ofen und Holz</h6> -->
          </div>
        </div>
        </div>
    </div>

    </main>


</template>

<script>


export default {
  computed: {
    isLoggedin() {
      return this.$store.getters.isAuthenticated;
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      i: 0,
      // txt: 'SIDANET',
      txt: 'Simple Data Network',
      txtsmall: 'SIDANET',
      speed: 150,
      innerHtmlText: '',
      isFirst: false,
      isFull: false,
      sidanetlonghidden: false,
      timeoutstart: null,
      timeoutincrease: null,
      timeoutdecrease: null,
      sidanetclass: 'sidanet',
      sidanetlongclass: 'sidanet-long',
      sidanethidden: true,
      sidanethiddengray: true,
      titleSi: 'SI',
      titleMple: 'MPLE ',
      titleDa: 'DA',
      titleTa: 'TA ',
      titleNet: 'NET',
      titleWork: 'WORK',
    };
  },
  created() {
    this.closeMenu();
    this.startTypeEffect();
  },
  methods: {
    startTypeEffect() {
      console.log("timer wird als erstes gestartet");
      this.timeoutstart = setTimeout(() => {
          console.log("erster timer triggert.");
          this.typeEffect();
        }, 2500);
    },
    typeEffect() {
      clearTimeout(this.timeoutstart);
      console.log('i: '+this.i+', txt.len: '+this.txt.length+', isFull: '+this.isFull);
      if (this.i < this.txt.length && this.isFull == false) {
        this.innerHtmlText += this.txt.charAt(this.i);
        this.typeWriter = true;
        this.i++;
        
        this.timeoutincrease = setTimeout(() => {
          this.typeEffect();
          console.log("2ter timer triggert.");
        }, this.speed);
        // console.log(this.txt.charAt(this.i));
      } else if (this.i > 0) {
        if(this.isFull == false) {
          this.timeoutdecrease = setTimeout(() => {
            clearTimeout(this.timeoutincrease);
            console.log("3ter timer triggert.");
            console.log("wir starten hier wieder zurück");
            this.isFull = true;
            
            this.sidanetlongclass = 'sidanet-long-fade-out';
            this.typeEffect();
          }, this.speed+2000);
        } else {
          this.i--;
          
          if (this.i == 0) {
            console.log("stopped");
            clearTimeout(this.timeoutdecrease);
            
            this.timeoutopacity = setTimeout(() => {
              clearTimeout(this.timeoutopacity);
              this.sidanethidden = false;
              this.sidanetlonghidden = true;

            }, 3000);
            this.timeoutopacityin = setTimeout(() => {
              clearTimeout(this.timeoutopacityin);
              this.sidanetclass = 'sidanet-fade-in';
            }, 5000);
          } else {
            this.typeEffect();
          }
        }
      }
    },
    closeMenu() {
      var toggler = document.getElementById('navbarCollapse');
      try {
        toggler.classList.remove("hide");
      } catch (error) {
        toggler.classList.remove("show");
      } 

    },
    expandContract() {
      let el1 = document.getElementById("expand-contract1");
      let el2 = document.getElementById("expand-contract2");
      let el3 = document.getElementById("expand-contract3");
      console.log("expanding");
      console.log(el1);
      console.log(el2);
      console.log(el3);
      // el1.classList.toggle('expanded');
      // el2.classList.toggle('expanded');
      // el3.classList.toggle('expanded');
      
      // el1.classList.toggle('collapsed');
      // el2.classList.toggle('collapsed');
      // el3.classList.toggle('collapsed');
    },
    trythisButton() {

      var w1 = document.getElementById("wrapper1");
      var w2 = document.getElementById("wrapper2");
      var w3 = document.getElementById("wrapper3");
      var l = document.getElementById("sidaP");
      console.log("found all");
      console.log(w1);
      console.log(l);

      w1.hidden  = true;
      w2.hidden  = true;
      w3.hidden  = true;
      
      
    },
  }
};


</script>

<style>


#myelement {
  position: relative;
  overflow: hidden;
}

.mycontainer {
  width: 100%;
  height: 100%;
  /* background: url('../../background-digital-services-with-cables-other-line_no_bg.png') 0 0 repeat; */
}

.row {
  margin-top: 5rem;
  margin-bottom: 5rem;
}





.shadows {
    position: relative;
    text-transform: uppercase;
    text-shadow: -15px 5px 20px #ced0d3;
    color: white;
    letter-spacing: -0.05em;
    font-family: 'Anton', Arial, sans-serif;
    user-select: none;
    text-transform: uppercase;
    font-size: 150px;
    transition: all 0.25s ease-out;
}
.shadows:hover {
    text-shadow: -16px 6px 15px #ced0d3;
}

@keyframes showTopText {
  0% { transform: translate3d(0, 100%, 0); }
  40%, 60% { transform: translate3d(0, 50%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}
@keyframes showBottomText {
  0% { transform: translate3d(0, -100%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}
@keyframes hideSidaBottomText {
  /* 0% { transform: translate3d(0, -100%, 0); }
  100% { transform: translate3d(0, 0, 100%); } */
  0% { width:100% }
  100% { width:0% }
}
.animated-title {
  color: #222;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 200;
  height: 90vmin;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90vmin;
  
}
.animated-title > div {
  height: 50%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
.animated-title > div div {
  font-size: 7vmin;
  padding: 2vmin 0;
  position: absolute;
}
.animated-title > div div span {
  display: block;
}
.animated-title > div.text-top {
  border-bottom: 1vmin solid #000;
  top: 0;
}
.animated-title > div.text-top div {
  animation: showTopText 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  bottom: 0;
  transform: translate(0, 100%);
}
.animated-title > div.text-top div span:first-child {
  color: #767676;
}
.animated-title > div.text-bottom {
  bottom: 0;
}
.animated-title > div.text-bottom div {
  animation: showBottomText 0.5s;
  animation-delay: 1.75s;
  animation-fill-mode: forwards;
  top: 0;
  transform: translate(0, -100%);
}

.animated-title > div.text-bottom div p {
  animation: hideSidaBottomText 0.5s;
  animation-delay: 2s;
  top: 0;
  transform: translate(0, 100%);
  color: #636363;
}


/* .gray-title-hidden {
  transition: opacity 3s ease-in-out,width 3s ease-out;
  width: 0;
  opacity: 0;
}

.gray-title{
  width: 100%;
  opacity: 1;
} */

.sidanet-long-fade-out {
  transition: opacity 3s ease-out,width 3s;
  width: 0;
  opacity: 0;
}

.sidanet-long {
  width: 100%;
  opacity: 1;
}

.sidanet-fade-in {
  transition: opacity 4s ease-in;
  transition-delay: 2s;
  opacity: 1;
}

.sidanet {
  opacity: 0;
}



.btn-new {
	background-color: #000003;
	border-radius: 5px;
	border: 0;
	float: right;
	color: white;
	padding:10px;
}
.btn-new:hover {
	background-color: #000003;
	color: white;
} 
* {
  margin: 0;
  padding: 0;
}
.text-item {
  position: fixed;
  top: 50%;
  left: 48%;
  transform: translateX(-50%) translateY(-50%);
  list-style: none;
  border-bottom: 0;
  letter-spacing: 2px;
}

.text-item.hidden {
  border-bottom: 1px solid #000000;
}

.text-item li {
  display: block;
  float: left;
  font-weight: bold;
  font-size: 2rem;
  color: #000000;
  opacity: 1;
  transition: all 1.5s ease-in;
  max-width: 2em;
}
.text-item.hidden li.spaced {
  padding-left: 0;
}
.text-item li.spaced {
  padding-left: 0.5em;
}

.text-item.hidden li.shade {
  opacity: 0;
  max-width: 0;
}
  
/* 
#wrapper1 {
  background: #ccc;
  overflow: hidden;
  transition: height 200ms;
} */



/* Hide and Seek */

/* #container {
   border: 1px solid black;
   padding: 15px;
}

#top-section {
  border-bottom: 1px solid red;
} */

/* #expand-container1,#expand-container2,#expand-container3 {
  overflow: hidden;
}

#expand-contract {
  border-bottom: 1px solid red;
  margin-top: -100%;
  transition: all 1s;
}

#expand-contract.expanded {
   background-color: green;
   margin-top: 0;
} */



</style>

