<template>

    <the-header></the-header>
    <div class="container"  style="margin-top: 100px; padding-bottom:80px;">
        <!-- <div class="row"> -->
            <!-- <the-menu></the-menu> -->
            <router-view v-slot="slotProps">
                <transition name="fade" mode="out-in">
                    <component :is="slotProps.Component"></component>
                </transition>
            </router-view>
        <!-- </div> -->
    </div>
    <!-- <div class="b-example-divider"></div> -->
    <the-footer></the-footer>
</template>


<script>
import  TheHeader  from './components/layout/TheHeader.vue';
import  TheFooter  from './components/layout/TheFooter.vue';

// CSS
import "./assets/sidanet-custom-bootstrap.css";
import "./assets/sidanet-custom.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

//JS
import "bootstrap/dist/js/bootstrap.min.js";

export default {
    components: {
        TheHeader,
        TheFooter,
    },
    created() {
      this.$store.dispatch('tryLogin');
    },
    methods: {
    }
}
</script>

<style>

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-active {
    transition: opacity 0.3s ease-out;
}

.fade-leave-active {
    transition: opacity 0.3s ease-in;
}

.fade-leave-from,
.fade-enter-to {
    opacity: 1;
}


/* 
.bd-placeholder-img {
        font-size: 1.125rem;
        text-anchor: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
      }

      @media (min-width: 768px) {
        .bd-placeholder-img-lg {
          font-size: 3.5rem;
        }
      }

      .b-example-divider {
        width: 100%;
        height: 3rem;
        background-color: rgba(0, 0, 0, .1);
        border: solid rgba(0, 0, 0, .15);
        border-width: 1px 0;
        box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
      }

      .b-example-vr {
        flex-shrink: 0;
        width: 1.5rem;
        height: 100vh;
      }

      .bi {
        vertical-align: -.125em;
        fill: currentColor;
      }

      .nav-scroller {
        position: relative;
        z-index: 2;
        height: 2.75rem;
        overflow-y: hidden;
      }

      .nav-scroller .nav {
        display: flex;
        flex-wrap: nowrap;
        padding-bottom: 1rem;
        margin-top: -1px;
        overflow-x: auto;
        text-align: center;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
      }

      .btn-bd-primary {
        --bd-violet-bg: #712cf9;
        --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

        --bs-btn-font-weight: 600;
        --bs-btn-color: var(--bs-white);
        --bs-btn-bg: var(--bd-violet-bg);
        --bs-btn-border-color: var(--bd-violet-bg);
        --bs-btn-hover-color: var(--bs-white);
        --bs-btn-hover-bg: #6528e0;
        --bs-btn-hover-border-color: #6528e0;
        --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
        --bs-btn-active-color: var(--bs-btn-hover-color);
        --bs-btn-active-bg: #5a23c8;
        --bs-btn-active-border-color: #5a23c8;
      }

      .bd-mode-toggle {
        z-index: 1500;
      }

      .bd-mode-toggle .dropdown-menu .active .bi {
        display: block !important;
      } */
    
      body::-webkit-scrollbar {
  width: 0.3em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: #13837f;
  outline: 1px solid slategrey;
}

@media print
{    
    header footer .navbar  
    {
        display: none;
    }
}

</style>