<template>
    <div>
        <base-dialog :show="!!error" title="Fehler" @close="handleError">
            <p>{{ error }}</p>
        </base-dialog>
        <base-dialog :show="isLoading" title="Einloggen..." fixed>
            <base-spinner></base-spinner>
        </base-dialog>
        <main class="form-signin w-100 m-auto">
            <form data-bitwarden-watching="1"  @submit.prevent="submitLoginForm">
                <!-- <img class="mb-4" src="/docs/5.3/assets/brand/bootstrap-logo.svg" alt="" width="72" height="57"> -->
                <h1 class="h3 mb-3 fw-normal">Login</h1>

                <div class="form-floating">
                <input type="text" class="input form-control" id="username" placeholder="Benutzername" v-model.trim="username">
                <label for="floatingInput">Benutzername</label>
                </div>
                <div class="form-floating">
                <input type="password" class="form-control" id="userpwd" placeholder="Passwort" v-model.trim="userpwd">
                <label for="floatingPassword">Passwort</label>
                </div>

                <!-- <div class="form-check text-start my-3">
                <input class="form-check-input" type="checkbox" value="remember-me" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                    Remember me
                </label>
                </div> -->
                <p v-if="!formIsValid">Logindaten dürfen nicht leer sein!</p>
                <!-- <button class="btn btn-primary w-100 py-2" type="submit" style="margin-top: 10px;">Log mich ein</button> -->
                <base-button class="w-100 py-2" type="submit">Log mich ein</base-button>
                <!-- <p class="mt-5 mb-3 text-body-secondary">© 2019–2024</p> -->
            </form>
        </main>
    </div>

</template>

<script>
export default {
    data() {
        return {
            username: '',
            userpwd: '',
            formIsValid: true,
            mode: 'login',
            sessionId: '',
            error: null,
            isLoading: false,
        };
    },
    created() {
        this.closeMenu();
    },
    methods: {
    closeMenu() {
      var toggler = document.getElementById('navbarCollapse');
      try {
        toggler.classList.remove("hide");
      } catch (error) {
        toggler.classList.remove("show");
      } 

    },
        async submitLoginForm() {
            this.isLoading = true;
            console.log("isloading true");
            this.formIsValid = true;
            if (this.username === '' || this.userpwd === '') {
                this.formIsValid = false;
                return
            }
            try {
                if (this.mode === 'login') {
                    await this.$store.dispatch('login',{
                        username: this.username,
                        userpwd: this.userpwd,
                    });
                    this.isLoading = false;
                    console.log("isloading false");
                    if (localStorage.getItem('token') != null) {
                        this.$router.replace('/shop');
                    }
                }
            } catch (error) {
                
                this.isLoading = false;
                console.log("isloading false");
                this.error = error.message;
            }
            
        },
        handleError() {
            this.error = null;
        }
    }
}
</script>

<style>
.form-signin {
  max-width: 330px;
  padding: 1rem;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>