<template>
    <main class="flex-shrink-0">

      <div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center  mycontainer ">
        <div class="col-md-6 p-lg-5 mx-auto my-5">
          <h2 class="display-2 fw-light">Shop</h2>
          
        </div>
        <div class="product-device shadow-sm d-none d-md-block"></div>
        <div class="product-device product-device-2 shadow-sm d-none d-md-block"></div>
      </div>


      <hr class="featurette-divider">
      
  <div class="px-4 pt-5 my-5 text-center border-bottom featurette">
    <h1 class="display-5 fw-light text-body-emphasis">Message Caller</h1>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">Sende Nachrichten und Kontrolliere dein Eigenheim per Telefonanruf.</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
        <!-- <button type="button" class="btn btn-primary btn-lg px-4 me-sm-3">Primary button</button>
        <button type="button" class="btn btn-outline-secondary btn-lg px-4">Secondary</button> -->
      </div>
    </div>
    <div class="overflow-hidden" style="max-height: 35vh;">
      <div class="container px-5">
        <img src="../../pics/openart-e53d4affb37549ccb0988fcc60535578_raw.jpg" class="img-fluid border rounded-3 shadow-lg mb-4" alt="Example image" width="700" height="500" loading="lazy">
      </div>
    </div>
  </div>


  



<!-- Three columns of text below the carousel -->
<div class="row text-center" style="margin-top: 10em;">
  <div class="col-lg-4">
    <img class="bd-placeholder-img rounded-circle" src="../../pics/smartphone-in-the-shape-of-an--1-.png" width="140" height="140"   focusable="false">
    <h2 class="fw-normal" style="margin-top: 2rem;">1 Jahr</h2>
    <p style="margin-top: 2rem;">Zum anfangen und lernen, wie gut einem der Dienst gefällt.</p>
  </div><!-- /.col-lg-4 -->
  <div class="col-lg-4">
    <img class="bd-placeholder-img rounded-circle" src="../../pics/smartphone-in-the-shape-of-an--3-.png" width="140" height="140"  focusable="false">
    <h2 class="fw-normal" style="margin-top: 2rem;">3 Jahre</h2>
    <p style="margin-top: 2rem;">Für die, die gern richtig anpacken wollen.</p>
  </div><!-- /.col-lg-4 -->
  <div class="col-lg-4">
    <img class="bd-placeholder-img rounded-circle" src="../../pics/smartphone-in-the-shape-of-an--5-.png" width="140" height="140" focusable="false">
    <h2 class="fw-normal" style="margin-top: 2rem;">5 Jahre</h2>
    <p style="margin-top: 2rem;">Für alle die sich nichts besseres vorstellen können.</p>
  </div><!-- /.col-lg-4 -->
</div><!-- /.row -->

<!-- START THE FEATURETTES -->

<hr class="featurette-divider">

<div class="row featurette">
  <div class="col-md-7">
    <h2 class="featurette-heading fw-normal lh-1">Sprache wiedergeben lassen <span class="text-body-secondary"></span></h2>
    <p class="lead text-secondary">Deine eigenen Informationen oder Events vom Smarthome übers Telefon vorlesen lassen.</p>
  </div>
  <div class="col-md-5">
    <img class="bd-placeholder-img rounded" src="../../pics/openart-image_b5e4126e_1725381889340_raw.jpg" width="200" height="200" focusable="false">
  </div>
</div>

<hr class="featurette-divider">

<div class="row featurette">
  <div class="col-md-7 order-md-2">
    <h2 class="featurette-heading fw-normal lh-1">Befehle per Tastendruck ausführen <span class="text-body-secondary"></span></h2>
    <p class="lead text-secondary">Ermöglicht komplexe Aufgaben und Schritte mit nur einer einfachen Handlung im Telefonat zu erledigen, was Zeit spart und die Produktivität steigert!</p>
  </div>
  <div class="col-md-5 order-md-1">
    <img class="bd-placeholder-img rounded" src="../../pics/openart-image_15e32560_1725394067796_raw.jpg" width="200" height="200" focusable="false">
  </div>
</div>

<hr class="featurette-divider">

<div class="row featurette">
  <div class="col-md-7">
    <h2 class="featurette-heading fw-normal lh-1">Vielseitig einsetzbar <span class="text-body-secondary"></span></h2>
    <p class="lead text-secondary">Dadurch dass man die möglichkeit hat selbst zu entscheiden, welche Informationen wiedergegeben werden, hat man höchste kompatiblität in verschiedensten Bereichen des Alltags.</p>
  </div>
  <div class="col-md-5">
    <img class="bd-placeholder-img rounded" src="../../pics/openart-image_4189213f_1725394387378_raw.jpg" width="200" height="200" focusable="false">
  </div>
</div>

<hr class="featurette-divider">

<div class="row featurette">
  <div class="col-md-7 order-md-2">
    <h2 class="featurette-heading fw-normal lh-1">In jedem Smarthomesystem einsetzbar <span class="text-body-secondary"></span></h2>
    <p class="lead text-secondary">Solange die Funktion besteht, dass HTTP/HTTPS befehler versendet werden können.</p>
  </div>
  <div class="col-md-5 order-md-1">
    <img class="bd-placeholder-img rounded" src="../../pics/openart-image_3f75555a_1725395129666_raw.jpg" width="200" height="200" focusable="false">
  </div>
</div>


<hr class="featurette-divider">
<!-- /END THE FEATURETTES -->





  <div class="px-4 pt-5 my-5 text-center border-bottom featurette">
    <h1 class="display-5 text-body-emphasis">Anwendungsbeispiele</h1>
  </div>
<div class="banner">
        <div class="slider" style="--quantity: 4">
            <div class="item" style="--position: 1"><img src="../../pics/openart-image_7212d2e8_1725392499489_raw.jpg" alt=""><h4>Alarmsystem</h4></div>
            <div class="item" style="--position: 2"><img src="../../pics/openart-image_d86cc261_1725392774232_raw.jpg" alt=""><h4>Wasserschaden</h4></div>
            <div class="item" style="--position: 3"><img src="../../pics/openart-image_02d0d17c_1725393335439_raw.jpg" alt=""><h4>Türklingel</h4></div>
            <div class="item" style="--position: 4"><img src="../../pics/openart-image_7330eecc_1725393990366_raw.jpg" alt=""><h4>Offene Garage</h4></div>
        </div>

    </div>
    
    <div class="px-4 pt-5 my-5 text-center">
      <h1 class="display-5 fw-light text-body-emphasis">Du bestimmst was du hören willst.</h1>
    </div>


    <hr class="featurette-divider">
<!-- /END THE FEATURETTES -->



  <div class="row py-lg-8" style="margin-bottom: 7rem;">
      <div class="col-lg-8 col-md-8 mx-auto">
        <h1 class="fw-light display-2">Starte jetzt</h1>
        <p class="lead text-muted">mit deinem neuen kostenlosen Account und teste den Message Caller 14 Tage aus!</p>
        <p class="lead text-muted"></p>

        <div class="d-flex flex-row-reverse bd-highlight"  style="margin-top: 12rem;">
          <router-link v-if="!isLoggedin" to="/register" class="p-2 btn btn-primary fs-3"  aria-current="page">Ich will testen <i class="fas fa-arrow-right"></i></router-link>
          <router-link v-if="isLoggedin" to="/shop/selectabo" class="p-2 btn btn-primary fs-3"  aria-current="page">Ich will testen <i class="fas fa-arrow-right"></i></router-link>
          <router-link to="/login" class="p-2 btn btn-sm text-secondary align-bottom" aria-current="page">Hab schon, danke.</router-link>
        </div>
      </div>
    </div>




    </main>


</template>

<script>
export default {
  computed: {
    isLoggedin() {
      return this.$store.getters.isAuthenticated;
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
    };
  },
  created() {
    this.closeMenu();
  },
  methods: {
    closeMenu() {
      var toggler = document.getElementById('navbarCollapse');
      try {
        toggler.classList.remove("hide");
      } catch (error) {
        toggler.classList.remove("show");
      } 

    },
  }
};
</script>

<style>


#myelement {
  position: relative;
  overflow: hidden;
}

.mycontainer {
  width: 100%;
  height: 100%;
  /* background: url('../../background-digital-services-with-cables-other-line_no_bg.png') 0 0 repeat; */
}

.row {
  margin-top: 5rem;
  margin-bottom: 5rem;
}


/** Auto show */
.body {

  scroll-behavior: smooth;
}
.title-autoshow{
  /* opacity:0; */
  animation: titleAutoShowAnimation both;
  animation-timeline: view(70% 5%);
}
@keyframes titleAutoShowAnimation {
  from{opacity:0;
  transform: translateX(-500px) scale(0.5);}
  to{opacity: 1;
  transform: translate(0) scale(1);}
}
.content-autoshow{
  opacity:0;
  animation: contentAutoShowAnimation both;
  animation-timeline: view(70% 5%);
}
@keyframes contentAutoShowAnimation {
  from{opacity:0;
  transform: translateX(500px) scale(0.5);}
  to{opacity: 1;
  transform: translate(0) scale(1);}
}

.pic-autoshow{
  opacity:0;
  animation: picAutoShowAnimation both;
  animation-timeline: view(70% 5%);
}
@keyframes picAutoShowAnimation {
  from{opacity:0;
  transform: translateY(-500px) scale(0.1);}
  to{opacity: 1;
  transform: translate(0) scale(1);}
}

.autoBlur{
  animation: autoBlurAnimation linear both;
  animation-timeline: view();
}
@keyframes autoBlurAnimation {
  0% {
    filter: blur(40px);

  }  
  45%, 55% {
    filter: blur(0);
  }
  100% {
    filter: blur(40px);
  }
}


/** css banner 3d */

@import url('https://fonts.cdnfonts.com/css/ica-rubrik-black');
@import url('https://fonts.cdnfonts.com/css/poppins');

.banner{
    width: 100%;
    height: 80vh;
    text-align: center;
    overflow: hidden;
    position: relative;
}
.banner .slider{
    position: absolute;
    width: 200px;
    height: 250px;
    top: 10%;
    left: calc(50% - 100px);
    transform-style: preserve-3d;
    transform: perspective(1000px);
    animation: autoRun 20s linear infinite;
    z-index: 2;
}
@keyframes autoRun{
    from{
        transform: perspective(1000px) rotateX(-16deg) rotateY(0deg);
    }to{
        transform: perspective(1000px) rotateX(-16deg) rotateY(360deg);
    }
}

.banner .slider .item{
    
  
  
    position: absolute;
    inset: 0 0 0 0;
    transform: 
        rotateY(calc( (var(--position) - 1) * (360 / var(--quantity)) * 1deg))
        translateZ(450px);
}

.banner .slider .item img:hover{
  transform: scale(110%)
  
}

.featurette{
    margin: 5em; 
}

.banner .slider .item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s;
}
.banner .content{
    position: absolute;
    bottom: 0;
    /* left: 60%; */
    /* transform: translateX(-50%); */
    width: min(1400px, 100vw);
    /* height: max-content; */
    padding-bottom: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
}
.banner .content h1{
    /* font-family: 'ICA Rubrik'; */
    font-size: 4em;
    /* line-height: 1em; */
    color: #25283B;
    position: relative;
}
.banner .content h1::after{
    position: absolute;
    inset: 0 0 0 0;
    content: attr(data-content);
    z-index: 2;
    -webkit-text-stroke: 2px #d2d2d2;
    color: transparent;
}
.banner .content .author{
    font-family: Poppins;
    text-align: right;
    margin-right: 20%;
    max-width: 200px;
}
.banner .content h2{
    font-size: 3em;
}

@media screen and (max-width: 1023px) {
    .banner .slider{
        width: 160px;
        height: 200px;
        left: calc(50% - 80px);
    }
    .banner .slider .item{
        transform: 
            rotateY(calc( (var(--position) - 1) * (360 / var(--quantity)) * 1deg))
            translateZ(300px);
    }
    .banner .content h1{
        text-align: center;
        width: 100%;
        text-shadow: 0 10px 20px #000;
        font-size: 7em;
    }
    .banner .content .author{
        color: #fff;
        /* padding: 20px; */
        text-shadow: 0 10px 20px #000;
        /* z-index: 2; */
        /* max-width: unset; */
        width: 100%;
        /* text-align: center; */
        /* padding: 0 30px; */
    }
}
@media screen and (max-width: 767px) {
    .banner .slider{
        width: 100px;
        height: 150px;
        left: calc(50% - 50px);
    }
    .banner .slider .item{
        transform: 
            rotateY(calc( (var(--position) - 1) * (360 / var(--quantity)) * 1deg))
            translateZ(180px);
    }
    .banner .content h1{
        font-size: 5em;
    }
}

/** extra css */


        body::before{
            position: absolute;
            width: min(1400px, 90vw);
            top: 10%;
            left: 50%;
            height: 90%;
            transform: translateX(-50%);
            content: '';
            /* background-image: url(images/bg.png);
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: top center; */
            pointer-events: none;
        }

</style>